.blog-text {
  overflow    : visible;
  white-space : pre-wrap;

  .content {
    width            : calc(50% + 60px);
    margin           : auto;
    padding          : 30px;
    background-color : #fff;
  }
  @media
      (max-width : 767px) {
    .content {
      width : 100%;
    }
  }

  .content {
    text-align : justify;
  }

  ul {
    white-space         : normal;
    list-style-position : outside;
  }

  ul ul {
    list-style : square url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==');
  }

  ul p {
    margin-bottom: 0;
  }

  li {
    margin : 0;
  }

  hr {
    width            : 100%;
    height           : 2px !important;
    border           : 0;
    background-image : linear-gradient(
                         to right,
                         rgba(#a4028f, 0),
                         rgba(#a4028f, 1),
                         rgba(#a4028f, 0)
                       );
  }

  .content::after {
    content : '';
    display : block;
    clear   : both;
  }

  .content div:nth-of-type(odd) {
    float : left;
  }

  .content div:nth-of-type(even) {
    float : right;
  }

  .content div:nth-of-type(odd) {
    clear : left;
  }

  .content div:nth-of-type(even) {
    clear : right;
  }

  .image-inline {
    display     : block;
    width       : 100%;
    margin      : 0px;
    padding-top : 0.5vw;
  }
}

.albumlink {
  align-items: center;
  align-self: center;
}
