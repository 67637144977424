.add-post {
  width        : 90%;
  margin-right : auto;
  margin-left  : auto;
  margin-top: 100px;
}

.add-post__textarea {
  height : 40vh;
}

.add-post__button {
  margin-right  : 20px;
  margin-left   : 20px;
  padding-right : 20px;
  padding-left  : 20px;
}

.add-post hr {
  width        : 100%;
  border : 0;
  height : 1px;
  background-image : linear-gradient(
                       to right,
                       rgba(#a4028f, 0),
                       rgba(#a4028f, 1),
                       rgba(#a4028f, 0)
                     );
  clear: right;
}

.list {
  width : 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.list-item {
  overflow: hidden;
  width: 250px;
  display: inline-block;
  img {
    width: 240px;
    height: 240px;
    object-fit: contain;
  }
}

form .row {
  margin-bottom : 10px;
}

form button {
  color           : #666666;
  border          : 1px solid #dcdcdc;
  border-radius   : 6px;
  background      : linear-gradient(
                      to bottom,
                      #ffffff 5%,
                      #f6f6f6 100%
                    );
  box-shadow      : inset 0px 1px 0px 0px #ffffff;
  text-decoration : none;
  text-shadow     : 0px 1px 0px #ffffff;
  font-family     : Arial;
  font-size       : 15px;
  font-weight     : bold;
  cursor          : pointer;
}

form button:hover {
  color  : var(--color);
  border : 1px solid var(--color);
}
