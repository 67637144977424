.editor {
  border: 1px dashed;
  text-align: justify;
  max-height: calc(100% - 51px);
  height: calc(100% - 51px);
  overflow-y: scroll
}

.richedit {
  text-align: justify;
  height: 30vw;
  max-height: 30vw;
}

.font-style {
  color: #888;
  height: 40px;
  width: 40px;
  font-size: 20px;
  margin: 5px;
  border: 1px solid;
  background-color: inherit;
  border-radius: 5px;
  text-align: center;
  transition: border 0.15s, background-color 0.15s, color 0.15s;
}

button:focus {
  outline: 0;
}

.font-style-selected {
  border: 1px solid;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
}

.right {
  float:right;
  width: 50%;
}

.image-inline {
  width: 30%;
  margin: 15px;
  padding: 5px;
}

hr.hr-focused {
  border: 1px solid #00f;
}

.image-inline-focused {
  border: 1px solid #00f;
}
