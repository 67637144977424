@import "../../common.scss";

.jumbotron-wrapper {
  position            : relative;
  width               : 100%;
  height              : 80vh;
  padding-bottom      : calc(80vh + 20px);
  overflow            : hidden;
  background-image    : url('/img/ajax-loader.gif');
  background-repeat   : no-repeat;
  background-position : center;

  .loaded {
    position            : relative;
    width               : 100%;
    height              : 80vh;
    background-position : center;
    background-size     : cover;
  }

  .text {
    position      : absolute;
    bottom        : 0;
    left          : 0;
    margin-bottom : 1em;
    margin-left   : 1em;
  }

  .title {
    color       : #fff;
    text-shadow : 0px 0px 10px $theme-color,
                  0px 0px 30px $theme-color,
                  0px 0px 50px $theme-color;
    font-family : 'Caveat',
                  cursive;
    font-size   : 6em;
  }

}
