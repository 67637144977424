/**
 * @import
 * "https://fonts.googleapis.com/css?family=Amita|Berkshire+Swash|Caveat|Caveat+Brush|Charmonman:700|Dancing+Script:700|Devonshire|Grand+Hotel|Just+Me+Again+Down+Here|Kalam|Kaushan+Script|Lemonada|Marck+Script|Sedgwick+Ave|Mali:700|Patrick+Hand";
 */

@import url("https://fonts.googleapis.com/css?family=Caveat|Lobster");

:root {
  /* --selected-border-color: #ff02a1; */
  --image-size-width  : 240px;
  --image-size-height : 220px;
  --shadow-right      : 3px;
  --shadow-left       : 3px;
  --shadow-height     : 3;
}

.add-album{
  margin-top:90px;
}

.tile_edit {
  /* Area box */
  z-index    : 0;
  position   : relative;
  width      : calc(var(--image-size-width) + 24px);
  height     : calc(var(--image-size-height) + 64px);
  margin     : 8px auto;
  padding    : 10px;
  overflow   : hidden;
  transition : transform var(--transition-time),
               border-color var(--transition-time),
               z-index var(--transition-time),
               box-shadow var(--transition-time);
}

.tile_edit_border {
  border           : 1px solid #000;
  background-color : #fff;
  box-shadow       : var(--shadow-right) var(--shadow-left) 5px 0px rgba(0,0,0,0.53);
}

.tile_edit_cover {
  z-index : 1;
  transform : scale(1.2);
  transition : transform var(--transition-time);
}

.tile_edit__img {
  width      : var(--image-size-width);
  height     : var(--image-size-height);
  object-fit : cover;
}

.tile_edit input {
  /* Tile name */
  position         : absolute;
  right            : 0;
  bottom           : 0;
  width            : 100%;
  color            : #000;
  opacity          : 0.7;
  border           : none;
  border-color     : transparent;
  background-color : #fff;
  outline          : none;
  text-align       : center;
  text-shadow      : 1px 1px 5px rgba(0, 0, 0, 0.3);
  font-family      : 'Caveat',
                     cursive;
  font-size        : 1.54em;
  font-style       : normal;
  font-weight      : 600;
  line-height      : 2;
  transition       : font-size var(--transition-time),
                     color var(--transition-time),
                     background-color var(--transition-time),
                     text-shadow var(--transition-time);
}

@media
  (xs) {
  tile {
    margin : 0 auto;
  }
}
