@import url("https://fonts.googleapis.com/css?family=Caveat|Lobster");
@import '../../../../common.scss';

$image-size-width  : 240px;
$image-size-height : 220px;
$transition-time   : 0.3s;
$angle             : 5deg;
$angle-cos         : 0.9659258262890682867497431997289;
$angle-sin         : 0.25881904510252076234889883762405;

// needed for access for JS.
:tile {
  --scale         : 1.2;
  --shadow-height : 3;
  --shadow-right  : 3px;
  --shadow-left   : 3px;
}

.tile {
  z-index          : 0;
  position         : relative;
  width            : calc(#{$image-size-width} + 24px);
  height           : calc(#{$image-size-height} + 64px);
  margin           : 8px auto;
  padding          : 10px 10px 6px 10px;
  overflow         : hidden;
  border           : 1px solid #000;
  background-image : url('/img/old_paper2.jpg');
  box-shadow       : var(--shadow-right) var(--shadow-left) 5px 0px rgba(0,0,0,0.53);
  transition       : transform $transition-time,
                     border-color $transition-time,
                     z-index $transition-time,
                     box-shadow $transition-time;

  .title {
    width            : 100%;
    height           : 40px;
    color            : #000;
    opacity          : 0.7;
    background-color: #0000;
    text-align       : center;
    text-shadow      : 1px 1px 5px rgba(0, 0, 0, 0.3);
    font-family      : 'Lobster',
                       cursive;
    font-size        : 1.54em;
    font-style       : normal;
    font-weight      : 600;
  }

  .cover {
    width         : $image-size-width;
    height        : $image-size-height;
    margin-bottom : 6px;
    object-fit    : cover;
  }
}

.tile__edit {
  z-index  : 1;
  position : absolute;
  right    : 0;
  bottom   : 0;
  width    : 32px;
  height   : 32px;
  float    : right;
  color    : #f00;
  transition: 0.1s;
}

.tile:hover {
  z-index      : 100;
  border-color : #000;
  box-shadow   : calc( var(--shadow-height) * (#{$angle-cos} * var(--shadow-right) + #{$angle-sin} * var(--shadow-left)) ) calc(var(--shadow-height) * (#{$angle-cos} * var(--shadow-left) - #{$angle-sin} * var(--shadow-right))) 5px 5px rgba(#000000, 0.4);
  transform    : scale(var(--scale)) rotate($angle);
}

a {
  text-decoration : none !important;
}

@media
  (xs) {
  tile {
    margin : 0 auto;
  }
}
