@import url("https://fonts.googleapis.com/css?family=Courgette|Lobster|Mali:700|Patrick+Hand|Sedgwick+Ave");
@import "../../common.scss";

$logo-height : 9vw;
$logo-angle  : 270deg;
$earth-size  : 12vw;

header {
  padding-top : 73px;
}

.nav-link {
  color          : $theme-color;
  text-align     : center;
  font-family    : 'Lobster',
                   cursive;
  font-size      : 1.7em;
  letter-spacing : 1.2px;
}

.nav-link:hover {
  color            : #fff;
  background-color : $theme-color;
}

.navbar-toggler {
  color: #a4028f88;
  border-color: #a4028f80;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(164, 2, 143, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler:hover {
  color: #a4028f;
  border-color: #a4028f;
}

.navbar-toggler:hover .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(164, 2, 143, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.hideable-navbar {
  display: block;
}

.nonhideable-navbar {
  display: none;
}

@media (min-width: 992px) {
  .hideable-navbar {
    display: none;
  }
  .nonhideable-navbar {
    display: block;
  }
}

header h1 {
  /* Blog name */
  z-index     : 1040;
  position    : absolute;
  top         : -2.7vw;
  right       : calc(50% + 0vw);
  height      : calc(#{$logo-height} * 2);
  color       : $theme-color;
  text-shadow : 1px 1px 1px #222;
  font-family : 'Lobster',
                cursive;
  font-size   : 1.7vw;
  line-height : $logo-height;
  transform   : rotate(calc(#{$logo-angle} * (-1 / 2))) translateY(0px);
}

header h1 span {
  position : absolute;
  height   : calc(#{$logo-height} * 2);
}

#logo {
  z-index       : 1039;
  position      : absolute;
  top           : 0;
  left          : calc(50% - #{$earth-size} / 2);
  width         : $earth-size;
  height        : $earth-size;
  padding       : 2.2vw;
  border        : 1px solid #000;
  border-radius : 50%;
}

.nav-item {
  width : 14%;
}

@media (max-width: 991px) {
  .nav-item {
    width: auto;
  }
  .nav-link {
    text-align     : left;
  }
  #logo {
    display: none;
  }
  header h1 {
    display: none;
  }
}

.c1 {
  transform : rotate(calc(#{$logo-angle} * 0 / 16));
}

.c2 {
  transform : rotate(calc(#{$logo-angle} * 1 / 16));
}

.c3 {
  transform : rotate(calc(#{$logo-angle} * 2 / 16));
}

.c4 {
  transform : rotate(calc(#{$logo-angle} * 3 / 16));
}

.c5 {
  transform : rotate(calc(#{$logo-angle} * 4 / 16));
}

.c6 {
  transform : rotate(calc(#{$logo-angle} * 5 / 16));
}

.c7 {
  transform : rotate(calc(#{$logo-angle} * 6 / 16));
}

.c8 {
  transform : rotate(calc(#{$logo-angle} * 7 / 16));
}

.c9 {
  transform : rotate(calc(#{$logo-angle} * 8 / 16));
}

.c10 {
  transform : rotate(calc(#{$logo-angle} * 9 / 16));
}

.c11 {
  transform : rotate(calc(#{$logo-angle} * 10 / 16));
}

.c12 {
  transform : rotate(calc(#{$logo-angle} * 11 / 16));
}

.c13 {
  transform : rotate(calc(#{$logo-angle} * 12 / 16));
}

.c14 {
  transform : rotate(calc(#{$logo-angle} * 13 / 16));
}

.c15 {
  transform : rotate(calc(#{$logo-angle} * 14 / 16));
}

.c16 {
  transform : rotate(calc(#{$logo-angle} * 15 / 16));
}

.c17 {
  transform : rotate(calc(#{$logo-angle} * 16 / 16));
}

.our_location {
  color: #888;
}

.our_location:hover {
  color: #888;
  background-color: inherit;
}
