@import "../../../../common.scss";
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url("https://fonts.googleapis.com/css?family=Caveat|Lobster");



$transition-time : 0.3s;

.post {
  position       : relative;
  height         : 0;
  margin-bottom  : 20px;
  padding-bottom : 130%;
  overflow       : hidden;
  border-radius  : 6px;
  text-align     : left;
  align-items    : left;

  img {
    z-index    : 0;
    position   : absolute;
    width      : 100%;
    height     : 100%;
    transition : transform $transition-time;
  }

  .cover {
    position   : absolute;
    top        : 30%;
    left       : 0;
    width      : 100%;
    height     : 70%;
    opacity    : 1;
    background : linear-gradient(
                   to bottom,
                   rgba(0,0,0,0),
                   rgba(0,0,0,0)
                 );
    transition : opacity $transition-time;
  }

  .title {
    position    : absolute;
    bottom      : 0px;
    width       : 100%;
    color       : #fff;
    text-align  : center;
    text-shadow : 1px 0px 1px black, 0px 1px 1px black, -1px 0px 1px black, 0px -1px 1px black;
    margin-bottom: 0.5em;
    font-family : 'Caveat',
                  cursive;
    font-family: 'Lobster', cursive;
    font-size   : 2em;
    transition: 0.3s;
  }

  .reset {
    clear : both;
  }

  .edit {
    position            : absolute;
    top                 : 0px;
    right               : 0px;
    width               : 20%;
    height              : 20%;
    background-image    : url('/img/edit.png');
    background-repeat   : no-repeat;
    background-position : left;
    background-size     : contain;
    transition          : width $transition-time,
                          height $transition-time;
  }

  .edit:hover {
    width  : 30%;
    height : 30%;
  }
}

.post:hover {
  img {
    transform : scale(1.15);
  }
}
