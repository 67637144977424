.modal-wrapper {
  z-index         : 9999;
  display         : flex;
  position        : absolute;
  top             : 0;
  right           : 0;
  bottom          : 0;
  left            : 0;
  background      : rgba(0, 0, 0, 0.3);
  align-items     : center;
  justify-content : center;
}

.modal {
  display       : inline-block;
  position      : relative;
  width         : 200px;
  min-width     : 500px;
  border-radius : 5px;
  background    : #fff;
  text-align    : center;
}

.text {
  margin      : 20px 0;
  font-size   : 20px;
  font-weight : bold;
}

.buttons {
  display         : flex;
  margin          : 10px 0;
  justify-content : space-around;
}

.modal-button {
  display          : inline-block;
  margin           : 5px;
  padding          : 6px 24px;
  color            : #666666;
  border           : 1px solid #dcdcdc;
  border-radius    : 6px;
  background       : linear-gradient(
                       to bottom,
                       #ffffff 5%,
                       #f6f6f6 100%
                     );
  background-color : #000fff;
  box-shadow       : inset 0px 1px 0px 0px #ffffff;
  text-decoration  : none;
  text-shadow      : 0px 1px 0px #ffffff;
  font-family      : Arial;
  font-size        : 15px;
  font-weight      : bold;
  cursor           : pointer;
}

.modal-button:hover {
  background       : linear-gradient(
                       to bottom,
                       #f6f6f6 5%,
                       #ffffff 100%
                     );
  background-color : #f6f6f6;
  filter           : progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6f6f6', endColorstr='#ffffff',GradientType=0);
}

.modal-button:active {
  position : relative;
  top      : 1px;
}

.close {
  position         : absolute;
  top              : 10px;
  right            : 0px;
  border           : none;
  background-color : transparent;
  font-size        : 2em;
  transform        : translateY(-50%);
  cursor           : pointer;
}

.modal-content {
  display         : flex;
  height          : 150px;
  color           : #fff;
  background      : rgba(193, 29, 29, 0.5);
  align-items     : center;
  justify-content : center;
}
