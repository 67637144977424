.footer {
  .pi {
    float   : right;
    padding : 10px;
    color   : #aaaaaa;
  }

  .pi:hover {
    color           : var(--color);
    text-decoration : none;
  }

  a {
    img {
      margin: 10px;
      width: 48px;
    }
  }
}
