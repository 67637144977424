@import url("https://fonts.googleapis.com/css?family=Courgette|Lobster|Mali:700|Patrick+Hand|Sedgwick+Ave");
header {
  padding-top: 73px; }

.nav-link {
  color: #a4028f;
  text-align: center;
  font-family: 'Lobster', cursive;
  font-size: 1.7em;
  letter-spacing: 1.2px; }

.nav-link:hover {
  color: #fff;
  background-color: #a4028f; }

.navbar-toggler {
  color: #a4028f88;
  border-color: #a4028f80; }

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(164, 2, 143, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-toggler:hover {
  color: #a4028f;
  border-color: #a4028f; }

.navbar-toggler:hover .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(164, 2, 143, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.hideable-navbar {
  display: block; }

.nonhideable-navbar {
  display: none; }

@media (min-width: 992px) {
  .hideable-navbar {
    display: none; }
  .nonhideable-navbar {
    display: block; } }

header h1 {
  /* Blog name */
  z-index: 1040;
  position: absolute;
  top: -2.7vw;
  right: calc(50% + 0vw);
  height: calc(9vw * 2);
  color: #a4028f;
  text-shadow: 1px 1px 1px #222;
  font-family: 'Lobster', cursive;
  font-size: 1.7vw;
  line-height: 9vw;
  transform: rotate(calc(270deg * (-1 / 2))) translateY(0px); }

header h1 span {
  position: absolute;
  height: calc(9vw * 2); }

#logo {
  z-index: 1039;
  position: absolute;
  top: 0;
  left: calc(50% - 12vw / 2);
  width: 12vw;
  height: 12vw;
  padding: 2.2vw;
  border: 1px solid #000;
  border-radius: 50%; }

.nav-item {
  width: 14%; }

@media (max-width: 991px) {
  .nav-item {
    width: auto; }
  .nav-link {
    text-align: left; }
  #logo {
    display: none; }
  header h1 {
    display: none; } }

.c1 {
  transform: rotate(calc(270deg * 0 / 16)); }

.c2 {
  transform: rotate(calc(270deg * 1 / 16)); }

.c3 {
  transform: rotate(calc(270deg * 2 / 16)); }

.c4 {
  transform: rotate(calc(270deg * 3 / 16)); }

.c5 {
  transform: rotate(calc(270deg * 4 / 16)); }

.c6 {
  transform: rotate(calc(270deg * 5 / 16)); }

.c7 {
  transform: rotate(calc(270deg * 6 / 16)); }

.c8 {
  transform: rotate(calc(270deg * 7 / 16)); }

.c9 {
  transform: rotate(calc(270deg * 8 / 16)); }

.c10 {
  transform: rotate(calc(270deg * 9 / 16)); }

.c11 {
  transform: rotate(calc(270deg * 10 / 16)); }

.c12 {
  transform: rotate(calc(270deg * 11 / 16)); }

.c13 {
  transform: rotate(calc(270deg * 12 / 16)); }

.c14 {
  transform: rotate(calc(270deg * 13 / 16)); }

.c15 {
  transform: rotate(calc(270deg * 14 / 16)); }

.c16 {
  transform: rotate(calc(270deg * 15 / 16)); }

.c17 {
  transform: rotate(calc(270deg * 16 / 16)); }

.our_location {
  color: #888; }

.our_location:hover {
  color: #888;
  background-color: inherit; }
