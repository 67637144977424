.our-location {
  width            : calc(50% + 60px);
  margin           : auto;
  margin-top: calc(12vw - 73px);
  padding          : 30px;
  background-color : #fff;
  font-size: 1em;
  padding: 30px;
  .datetime{
    font-style: italic;
    font-weight: bold;
  }
  .image-inline {
    display     : block;
    width       : 100%;
    margin      : 0px;
    padding-top : 0.5vw;
  }
  .desc {
    margin-top: 20px;
    font-size: 0.6em;
  }
}

@media (max-width: 992px) {
  .our-location {
    margin-top: 0px;
  }
}
