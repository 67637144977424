body {
  margin      : 0;
  padding     : 0;
  font-family : sans-serif;
}

.App {
  text-align: center;
}

.background {
  background-image      : url('/img/background-small.png');
  background-attachment : fixed;
}

* {
  box-sizing : border-box;
}
