@import "../../common.scss";

@font-face {
  src : url("https://fonts.googleapis.com/css?family=Caveat|Lobster");
}

.album-viewer {
  z-index          : 1050;
  position         : fixed;
  top              : 0;
  left             : 0;
  width            : 100%;
  height           : 100%;
  overflow: hidden;
  background-color : #000d;
  text-align       : center;
  animation        : fadein 0.3s;

  .view {
    position: relative;
    height: calc(100% - 50px);

    .active {
      height: 100%;
    }
  }

  .thumbs {
    height: 51px;
    width: 100%;
    border-top: 1px solid #fff;
    margin:0;
    .thumb {
      .before, .after {
         width: calc(50% - 30px);
      }
      .notactive {
        opacity: 0.2;
        transition: 0.1s;
      }
      .middle {
        transition: 0.1s;
      }
      img {
        width: 50px;
        height: 50px;
        object-fit : contain;
      }
      .empty {
        width: 1000px;
      }
    }
    .thumb:hover {
      .notactive {
        opacity: 1;
      }
      .notactive:hover, .middle:hover {
        transform: translateY(-20%) scale(1.4);
        z-index: 100;
      }
    }
  }

  @keyframes fadein {
    from {
      opacity : 0;
    }

    to {
      opacity : 1;
    }
  }

  .spinner {
    position  : relative;
    top       : 50%;
    transform : perspective(1px) translateY(-50%);
  }

  .close-button {
    z-index    : 2001;
    position   : absolute;
    right: 0;
    top:0;
    width      : 32px;
    height     : 32px;
    float      : right;
    margin     : 16px;
    opacity    : 1;
    transition : transform 0.1s;
    object-fit : contain;
    cursor: pointer;
  }

  .close-button:hover {
    transform : scale(1.5);
  }

  .clear {
    clear : both;
  }

  .left-button {
    z-index  : 2000;
    position : absolute;
    top      : 0;
    left     : 0;
    width    : 5%;
    margin-left: 1%;
    height   : 100%;
    object-fit: contain;
    transition: transform 0.1s;
    cursor: pointer;
  }

  .left-button:hover, .right-button:hover {
    transform: scale(1.5);
  }

  .right-button {
    z-index  : 2000;
    position : absolute;
    right    : 0;
    bottom   : 0;
    margin-right: 1%;
    width    : 5%;
    height   : 100%;
    object-fit: contain;
    transition: transform 0.1s;
    cursor: pointer;
  }


  .active-transition-left {
    .photo, .text {
      transform: translateX(-100%);
      transition: 0.3s;
    }
  }

  .active-transition-right {
    .photo, .text {
      transform: translateX(100%);
      transition: 0.3s;
    }
  }

  .photo {
    position   : absolute;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    object-fit : contain;
    background-image: url('/img/ajax-loader.gif');
    background-repeat: no-repeat;
    background-position: center;
  }

  .prev {
    .photo, .text {
      transform: translateX(-100%);
    }
  }
  .next {
    .photo, .text {
      transform: translateX(100%);
    }
  }

  .next-transition, .prev-transition {
    .photo, .text {
      transform: translateX(0%);
      transition : 0.3s;
    }
  }

  .next-img, .prev-img {
    position   : absolute;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    object-fit : contain;
  }


  .text {
    z-index     : 2000;
    position    : absolute;
    bottom      : 0;
    width       : 100%;
    color       : #fff;
    text-align  : center;
    text-shadow : 2px 0px 2px #000,
                  0px 2px 2px #000,
                  -2px 0px 2px #000,
                  0px -2px 2px #000;
    font-family : 'Caveat',
                  cursive;
    font-size   : 2.54em;
    font-style  : normal;
    font-weight : 600;
  }
}

.fadeAlbumViewer {
  opacity    : 0;
  transition : opacity 0.3s;
}

@media (pointer: coarse) and (hover: none) {
  .album-viewer {
    .left-button {
      display: none;
    }
      .right-button {
        display: none;
      }
}
}

.disablescroll {
  overflow: hidden;
}

.videoWrapper {
  width: 100%;
  height:100%;

  iframe {
    height: 100%;
    width: 88%;
  }
}
