@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Caveat|Lobster);
@import url(https://fonts.googleapis.com/css?family=Caveat|Lobster);
@import url(https://fonts.googleapis.com/css?family=Courgette|Lobster|Mali:700|Patrick+Hand|Sedgwick+Ave);
@import url(https://fonts.googleapis.com/css?family=Caveat|Lobster);

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; }

.App {
  text-align: center; }

.background {
  background-image: url("/img/background-small.png");
  background-attachment: fixed; }

* {
  box-sizing: border-box; }

.feedlist {
  width: 100%;
  margin: auto;
  padding-left: 5%;
  padding-right: 5%; }

.post {
  position: relative;
  height: 0;
  margin-bottom: 20px;
  padding-bottom: 130%;
  overflow: hidden;
  border-radius: 6px;
  text-align: left;
  -webkit-align-items: left;
          align-items: left; }
  .post img {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .post .cover {
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    height: 70%;
    opacity: 1;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    transition: opacity 0.3s; }
  .post .title {
    position: absolute;
    bottom: 0px;
    width: 100%;
    color: #fff;
    text-align: center;
    text-shadow: 1px 0px 1px black, 0px 1px 1px black, -1px 0px 1px black, 0px -1px 1px black;
    margin-bottom: 0.5em;
    font-family: 'Caveat', cursive;
    font-family: 'Lobster', cursive;
    font-size: 2em;
    transition: 0.3s; }
  .post .reset {
    clear: both; }
  .post .edit {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20%;
    height: 20%;
    background-image: url("/img/edit.png");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    transition: width 0.3s, height 0.3s; }
  .post .edit:hover {
    width: 30%;
    height: 30%; }

.post:hover img {
  -webkit-transform: scale(1.15);
          transform: scale(1.15); }


.jumbotron-wrapper {
  position: relative;
  width: 100%;
  height: 80vh;
  padding-bottom: calc(80vh + 20px);
  overflow: hidden;
  background-image: url("/img/ajax-loader.gif");
  background-repeat: no-repeat;
  background-position: center; }
  .jumbotron-wrapper .loaded {
    position: relative;
    width: 100%;
    height: 80vh;
    background-position: center;
    background-size: cover; }
  .jumbotron-wrapper .text {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 1em;
    margin-left: 1em; }
  .jumbotron-wrapper .title {
    color: #fff;
    text-shadow: 0px 0px 10px #a4028f, 0px 0px 30px #a4028f, 0px 0px 50px #a4028f;
    font-family: 'Caveat', cursive;
    font-size: 6em; }


@font-face {
  src: url("https://fonts.googleapis.com/css?family=Caveat|Lobster"); }

.album-viewer {
  z-index: 1050;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000d;
  text-align: center;
  -webkit-animation: fadein 0.3s;
          animation: fadein 0.3s; }
  .album-viewer .view {
    position: relative;
    height: calc(100% - 50px); }
    .album-viewer .view .active {
      height: 100%; }
  .album-viewer .thumbs {
    height: 51px;
    width: 100%;
    border-top: 1px solid #fff;
    margin: 0; }
    .album-viewer .thumbs .thumb .before, .album-viewer .thumbs .thumb .after {
      width: calc(50% - 30px); }
    .album-viewer .thumbs .thumb .notactive {
      opacity: 0.2;
      transition: 0.1s; }
    .album-viewer .thumbs .thumb .middle {
      transition: 0.1s; }
    .album-viewer .thumbs .thumb img {
      width: 50px;
      height: 50px;
      object-fit: contain; }
    .album-viewer .thumbs .thumb .empty {
      width: 1000px; }
    .album-viewer .thumbs .thumb:hover .notactive {
      opacity: 1; }
    .album-viewer .thumbs .thumb:hover .notactive:hover, .album-viewer .thumbs .thumb:hover .middle:hover {
      -webkit-transform: translateY(-20%) scale(1.4);
              transform: translateY(-20%) scale(1.4);
      z-index: 100; }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .album-viewer .spinner {
    position: relative;
    top: 50%;
    -webkit-transform: perspective(1px) translateY(-50%);
            transform: perspective(1px) translateY(-50%); }
  .album-viewer .close-button {
    z-index: 2001;
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;
    float: right;
    margin: 16px;
    opacity: 1;
    transition: -webkit-transform 0.1s;
    transition: transform 0.1s;
    transition: transform 0.1s, -webkit-transform 0.1s;
    object-fit: contain;
    cursor: pointer; }
  .album-viewer .close-button:hover {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  .album-viewer .clear {
    clear: both; }
  .album-viewer .left-button {
    z-index: 2000;
    position: absolute;
    top: 0;
    left: 0;
    width: 5%;
    margin-left: 1%;
    height: 100%;
    object-fit: contain;
    transition: -webkit-transform 0.1s;
    transition: transform 0.1s;
    transition: transform 0.1s, -webkit-transform 0.1s;
    cursor: pointer; }
  .album-viewer .left-button:hover, .album-viewer .right-button:hover {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  .album-viewer .right-button {
    z-index: 2000;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 1%;
    width: 5%;
    height: 100%;
    object-fit: contain;
    transition: -webkit-transform 0.1s;
    transition: transform 0.1s;
    transition: transform 0.1s, -webkit-transform 0.1s;
    cursor: pointer; }
  .album-viewer .active-transition-left .photo, .album-viewer .active-transition-left .text {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: 0.3s; }
  .album-viewer .active-transition-right .photo, .album-viewer .active-transition-right .text {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: 0.3s; }
  .album-viewer .photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-image: url("/img/ajax-loader.gif");
    background-repeat: no-repeat;
    background-position: center; }
  .album-viewer .prev .photo, .album-viewer .prev .text {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  .album-viewer .next .photo, .album-viewer .next .text {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  .album-viewer .next-transition .photo, .album-viewer .next-transition .text, .album-viewer .prev-transition .photo, .album-viewer .prev-transition .text {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: 0.3s; }
  .album-viewer .next-img, .album-viewer .prev-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; }
  .album-viewer .text {
    z-index: 2000;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    text-align: center;
    text-shadow: 2px 0px 2px #000, 0px 2px 2px #000, -2px 0px 2px #000, 0px -2px 2px #000;
    font-family: 'Caveat', cursive;
    font-size: 2.54em;
    font-style: normal;
    font-weight: 600; }

.fadeAlbumViewer {
  opacity: 0;
  transition: opacity 0.3s; }

@media (pointer: coarse) and (hover: none) {
  .album-viewer .left-button {
    display: none; }
  .album-viewer .right-button {
    display: none; } }

.disablescroll {
  overflow: hidden; }

.videoWrapper {
  width: 100%;
  height: 100%; }
  .videoWrapper iframe {
    height: 100%;
    width: 88%; }


:tile {
  --scale: 1.2;
  --shadow-height: 3;
  --shadow-right: 3px;
  --shadow-left: 3px; }

.tile {
  z-index: 0;
  position: relative;
  width: calc(240px + 24px);
  height: calc(220px + 64px);
  margin: 8px auto;
  padding: 10px 10px 6px 10px;
  overflow: hidden;
  border: 1px solid #000;
  background-image: url("/img/old_paper2.jpg");
  box-shadow: var(--shadow-right) var(--shadow-left) 5px 0px rgba(0, 0, 0, 0.53);
  transition: border-color 0.3s, z-index 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, border-color 0.3s, z-index 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, border-color 0.3s, z-index 0.3s, box-shadow 0.3s, -webkit-transform 0.3s; }
  .tile .title {
    width: 100%;
    height: 40px;
    color: #000;
    opacity: 0.7;
    background-color: #0000;
    text-align: center;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    font-family: 'Lobster', cursive;
    font-size: 1.54em;
    font-style: normal;
    font-weight: 600; }
  .tile .cover {
    width: 240px;
    height: 220px;
    margin-bottom: 6px;
    object-fit: cover; }

.tile__edit {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 32px;
  height: 32px;
  float: right;
  color: #f00;
  transition: 0.1s; }

.tile:hover {
  z-index: 100;
  border-color: #000;
  box-shadow: calc( var(--shadow-height) * (0.96593 * var(--shadow-right) + 0.25882 * var(--shadow-left))) calc(var(--shadow-height) * (0.96593 * var(--shadow-left) - 0.25882 * var(--shadow-right))) 5px 5px rgba(0, 0, 0, 0.4);
  -webkit-transform: scale(var(--scale)) rotate(5deg);
          transform: scale(var(--scale)) rotate(5deg); }

a {
  text-decoration: none !important; }

@media (xs) {
  tile {
    margin: 0 auto; } }

header {
  padding-top: 73px; }

.nav-link {
  color: #a4028f;
  text-align: center;
  font-family: 'Lobster', cursive;
  font-size: 1.7em;
  letter-spacing: 1.2px; }

.nav-link:hover {
  color: #fff;
  background-color: #a4028f; }

.navbar-toggler {
  color: #a4028f88;
  border-color: #a4028f80; }

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(164, 2, 143, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-toggler:hover {
  color: #a4028f;
  border-color: #a4028f; }

.navbar-toggler:hover .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(164, 2, 143, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.hideable-navbar {
  display: block; }

.nonhideable-navbar {
  display: none; }

@media (min-width: 992px) {
  .hideable-navbar {
    display: none; }
  .nonhideable-navbar {
    display: block; } }

header h1 {
  /* Blog name */
  z-index: 1040;
  position: absolute;
  top: -2.7vw;
  right: calc(50% + 0vw);
  height: calc(9vw * 2);
  color: #a4028f;
  text-shadow: 1px 1px 1px #222;
  font-family: 'Lobster', cursive;
  font-size: 1.7vw;
  line-height: 9vw;
  -webkit-transform: rotate(calc(270deg * (-1 / 2))) translateY(0px);
          transform: rotate(calc(270deg * (-1 / 2))) translateY(0px); }

header h1 span {
  position: absolute;
  height: calc(9vw * 2); }

#logo {
  z-index: 1039;
  position: absolute;
  top: 0;
  left: calc(50% - 12vw / 2);
  width: 12vw;
  height: 12vw;
  padding: 2.2vw;
  border: 1px solid #000;
  border-radius: 50%; }

.nav-item {
  width: 14%; }

@media (max-width: 991px) {
  .nav-item {
    width: auto; }
  .nav-link {
    text-align: left; }
  #logo {
    display: none; }
  header h1 {
    display: none; } }

.c1 {
  -webkit-transform: rotate(calc(270deg * 0 / 16));
          transform: rotate(calc(270deg * 0 / 16)); }

.c2 {
  -webkit-transform: rotate(calc(270deg * 1 / 16));
          transform: rotate(calc(270deg * 1 / 16)); }

.c3 {
  -webkit-transform: rotate(calc(270deg * 2 / 16));
          transform: rotate(calc(270deg * 2 / 16)); }

.c4 {
  -webkit-transform: rotate(calc(270deg * 3 / 16));
          transform: rotate(calc(270deg * 3 / 16)); }

.c5 {
  -webkit-transform: rotate(calc(270deg * 4 / 16));
          transform: rotate(calc(270deg * 4 / 16)); }

.c6 {
  -webkit-transform: rotate(calc(270deg * 5 / 16));
          transform: rotate(calc(270deg * 5 / 16)); }

.c7 {
  -webkit-transform: rotate(calc(270deg * 6 / 16));
          transform: rotate(calc(270deg * 6 / 16)); }

.c8 {
  -webkit-transform: rotate(calc(270deg * 7 / 16));
          transform: rotate(calc(270deg * 7 / 16)); }

.c9 {
  -webkit-transform: rotate(calc(270deg * 8 / 16));
          transform: rotate(calc(270deg * 8 / 16)); }

.c10 {
  -webkit-transform: rotate(calc(270deg * 9 / 16));
          transform: rotate(calc(270deg * 9 / 16)); }

.c11 {
  -webkit-transform: rotate(calc(270deg * 10 / 16));
          transform: rotate(calc(270deg * 10 / 16)); }

.c12 {
  -webkit-transform: rotate(calc(270deg * 11 / 16));
          transform: rotate(calc(270deg * 11 / 16)); }

.c13 {
  -webkit-transform: rotate(calc(270deg * 12 / 16));
          transform: rotate(calc(270deg * 12 / 16)); }

.c14 {
  -webkit-transform: rotate(calc(270deg * 13 / 16));
          transform: rotate(calc(270deg * 13 / 16)); }

.c15 {
  -webkit-transform: rotate(calc(270deg * 14 / 16));
          transform: rotate(calc(270deg * 14 / 16)); }

.c16 {
  -webkit-transform: rotate(calc(270deg * 15 / 16));
          transform: rotate(calc(270deg * 15 / 16)); }

.c17 {
  -webkit-transform: rotate(calc(270deg * 16 / 16));
          transform: rotate(calc(270deg * 16 / 16)); }

.our_location {
  color: #888; }

.our_location:hover {
  color: #888;
  background-color: inherit; }

.footer .pi {
  float: right;
  padding: 10px;
  color: #aaaaaa; }

.footer .pi:hover {
  color: var(--color);
  text-decoration: none; }

.footer a img {
  margin: 10px;
  width: 48px; }

.blog-text {
  overflow: visible;
  white-space: pre-wrap; }
  .blog-text .content {
    width: calc(50% + 60px);
    margin: auto;
    padding: 30px;
    background-color: #fff; }
  @media (max-width: 767px) {
    .blog-text .content {
      width: 100%; } }
  .blog-text .content {
    text-align: justify; }
  .blog-text ul {
    white-space: normal;
    list-style-position: outside; }
  .blog-text ul ul {
    list-style: square url("data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw=="); }
  .blog-text ul p {
    margin-bottom: 0; }
  .blog-text li {
    margin: 0; }
  .blog-text hr {
    width: 100%;
    height: 2px !important;
    border: 0;
    background-image: linear-gradient(to right, rgba(164, 2, 143, 0), #a4028f, rgba(164, 2, 143, 0)); }
  .blog-text .content::after {
    content: '';
    display: block;
    clear: both; }
  .blog-text .content div:nth-of-type(odd) {
    float: left; }
  .blog-text .content div:nth-of-type(even) {
    float: right; }
  .blog-text .content div:nth-of-type(odd) {
    clear: left; }
  .blog-text .content div:nth-of-type(even) {
    clear: right; }
  .blog-text .image-inline {
    display: block;
    width: 100%;
    margin: 0px;
    padding-top: 0.5vw; }

.albumlink {
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center; }

.add-post {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 100px; }

.add-post__textarea {
  height: 40vh; }

.add-post__button {
  margin-right: 20px;
  margin-left: 20px;
  padding-right: 20px;
  padding-left: 20px; }

.add-post hr {
  width: 100%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(164, 2, 143, 0), #a4028f, rgba(164, 2, 143, 0));
  clear: right; }

.list {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap; }

.list-item {
  overflow: hidden;
  width: 250px;
  display: inline-block; }
  .list-item img {
    width: 240px;
    height: 240px;
    object-fit: contain; }

form .row {
  margin-bottom: 10px; }

form button {
  color: #666666;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  background: linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer; }

form button:hover {
  color: var(--color);
  border: 1px solid var(--color); }

.editor {
  border: 1px dashed;
  text-align: justify;
  max-height: calc(100% - 51px);
  height: calc(100% - 51px);
  overflow-y: scroll; }

.richedit {
  text-align: justify;
  height: 30vw;
  max-height: 30vw; }

.font-style {
  color: #888;
  height: 40px;
  width: 40px;
  font-size: 20px;
  margin: 5px;
  border: 1px solid;
  background-color: inherit;
  border-radius: 5px;
  text-align: center;
  transition: border 0.15s, background-color 0.15s, color 0.15s; }

button:focus {
  outline: 0; }

.font-style-selected {
  border: 1px solid;
  border-radius: 5px;
  background-color: #fff;
  color: #000; }

.right {
  float: right;
  width: 50%; }

.image-inline {
  width: 30%;
  margin: 15px;
  padding: 5px; }

hr.hr-focused {
  border: 1px solid #00f; }

.image-inline-focused {
  border: 1px solid #00f; }

.upsert_section__input {
  margin-right: 10px;
  margin-left: 10px; }

.upsert_section__button {
  margin-right: 10px;
  margin-left: 10px; }

.modal-wrapper {
  z-index: 9999;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.modal {
  display: inline-block;
  position: relative;
  width: 200px;
  min-width: 500px;
  border-radius: 5px;
  background: #fff;
  text-align: center; }

.text {
  margin: 20px 0;
  font-size: 20px;
  font-weight: bold; }

.buttons {
  display: -webkit-flex;
  display: flex;
  margin: 10px 0;
  -webkit-justify-content: space-around;
          justify-content: space-around; }

.modal-button {
  display: inline-block;
  margin: 5px;
  padding: 6px 24px;
  color: #666666;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  background: linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
  background-color: #000fff;
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer; }

.modal-button:hover {
  background: linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
  background-color: #f6f6f6;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6f6f6', endColorstr='#ffffff',GradientType=0); }

.modal-button:active {
  position: relative;
  top: 1px; }

.close {
  position: absolute;
  top: 10px;
  right: 0px;
  border: none;
  background-color: transparent;
  font-size: 2em;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer; }

.modal-content {
  display: -webkit-flex;
  display: flex;
  height: 150px;
  color: #fff;
  background: rgba(193, 29, 29, 0.5);
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

/**
 * @import
 * "https://fonts.googleapis.com/css?family=Amita|Berkshire+Swash|Caveat|Caveat+Brush|Charmonman:700|Dancing+Script:700|Devonshire|Grand+Hotel|Just+Me+Again+Down+Here|Kalam|Kaushan+Script|Lemonada|Marck+Script|Sedgwick+Ave|Mali:700|Patrick+Hand";
 */
:root {
  /* --selected-border-color: #ff02a1; */
  --image-size-width: 240px;
  --image-size-height: 220px;
  --shadow-right: 3px;
  --shadow-left: 3px;
  --shadow-height: 3; }

.add-album {
  margin-top: 90px; }

.tile_edit {
  /* Area box */
  z-index: 0;
  position: relative;
  width: calc(240px + 24px);
  width: calc(var(--image-size-width) + 24px);
  height: calc(220px + 64px);
  height: calc(var(--image-size-height) + 64px);
  margin: 8px auto;
  padding: 10px;
  overflow: hidden;
  transition: border-color var(--transition-time), z-index var(--transition-time), box-shadow var(--transition-time), -webkit-transform var(--transition-time);
  transition: transform var(--transition-time), border-color var(--transition-time), z-index var(--transition-time), box-shadow var(--transition-time);
  transition: transform var(--transition-time), border-color var(--transition-time), z-index var(--transition-time), box-shadow var(--transition-time), -webkit-transform var(--transition-time); }

.tile_edit_border {
  border: 1px solid #000;
  background-color: #fff;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.53);
  box-shadow: var(--shadow-right) var(--shadow-left) 5px 0px rgba(0, 0, 0, 0.53); }

.tile_edit_cover {
  z-index: 1;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: -webkit-transform var(--transition-time);
  transition: transform var(--transition-time);
  transition: transform var(--transition-time), -webkit-transform var(--transition-time); }

.tile_edit__img {
  width: 240px;
  width: var(--image-size-width);
  height: 220px;
  height: var(--image-size-height);
  object-fit: cover; }

.tile_edit input {
  /* Tile name */
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  color: #000;
  opacity: 0.7;
  border: none;
  border-color: transparent;
  background-color: #fff;
  outline: none;
  text-align: center;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  font-family: 'Caveat', cursive;
  font-size: 1.54em;
  font-style: normal;
  font-weight: 600;
  line-height: 2;
  transition: font-size var(--transition-time), color var(--transition-time), background-color var(--transition-time), text-shadow var(--transition-time); }

@media (xs) {
  tile {
    margin: 0 auto; } }

.about {
  text-align: left; }
  .about img {
    margin-bottom: 50px;
    width: 100%; }

.our-location {
  width: calc(50% + 60px);
  margin: auto;
  margin-top: calc(12vw - 73px);
  padding: 30px;
  background-color: #fff;
  font-size: 1em;
  padding: 30px; }
  .our-location .datetime {
    font-style: italic;
    font-weight: bold; }
  .our-location .image-inline {
    display: block;
    width: 100%;
    margin: 0px;
    padding-top: 0.5vw; }
  .our-location .desc {
    margin-top: 20px;
    font-size: 0.6em; }

@media (max-width: 992px) {
  .our-location {
    margin-top: 0px; } }

